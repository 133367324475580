export default function linkify(message: string): string {
  const messageList = message.split(" ");
  return messageList.map(message => {
    if (message.includes("=>")) {
      const urlComponents = message.split("=>");
      const punctuations = [".", ",", ":", "!", "?"];
      let punctuation = "";
      if (punctuations.includes(urlComponents[1].split("").pop() as string)) {
        punctuation = urlComponents[1].split("").pop() as string;
        urlComponents[1] = urlComponents[1].slice(0, -1);
      }
      return `<a href="${urlComponents[1]}">${urlComponents[0]}</a>${punctuation}`;
    }
    return message;
  }).join(" ");
}
