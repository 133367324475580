

















































import { defineComponent } from "@vue/composition-api";
import AllNewsMessages from "@/components/news/AllNewsMessages.vue";
import CreateNewsMessage from "@/components/news/CreateNewsMessage.vue";
import CustomerFeedback from "@/components/feedback/CustomerFeedback.vue";
import ProFeedback from "@/components/feedback/ProFeedback.vue";
import StudentFeedback from "@/components/feedback/StudentFeedback.vue";

export default defineComponent({
  name: "NewsAndFeedback",
  components: { AllNewsMessages, CreateNewsMessage, CustomerFeedback, ProFeedback, StudentFeedback },
  metaInfo() {
    return {
      title: "Admin / Nyheter & feedback",
    };
  },
});
